import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import { defaultHelpId } from '../../constants';

const setDesktopGFPP = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  t,
) => {
  controllerBuilder
    .gfpp()
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('design', { behavior: 'DEFAULT' })
    .set('add', { behavior: 'HIDE' })
    .set('help', { id: defaultHelpId });
  controllerBuilder
    .gfpp('mobile')
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('design', { behavior: 'DEFAULT' })
    .set('help', { id: defaultHelpId });
};
const setMobileGFPP = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  t,
) => {
  controllerBuilder
    .gfpp('mobile')
    .set('presets', { behavior: 'HIDE' })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('design', { behavior: 'DEFAULT' })
    .set('help', { id: defaultHelpId });
};
export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: t('Widget_Name_Navigation_Widget'),
    nickname: 'navigationWidget',
  });
  setDesktopGFPP(controllerBuilder, editorSDK, t);
  setMobileGFPP(controllerBuilder, editorSDK, t);
};
