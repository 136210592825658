import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import { getRole } from '../../../utils/manifestUtils';
import { openDashboardPanel } from '../../../utils/dashboardUtils';
import { getFirstCollection } from '../../../services/dataServices';
import { CollectionWidgetComponentIds } from './constants';
import { getCollectionIdFromDynamicPage } from '../../../utils/pagesUtils';
import { FeaturedCollectionWidgetWidgetProps } from '../../featuredCollectionWidget/config/types';
import { COLLECTION_PAGE_AUTO_FOCUS_TARGETS } from '../../../constants';

enum FeaturedCollectionPosition {
  GalleryAncestorPosition = 9,
  TextsAncestorPosition = 10,
}

const CollectionConfigureMainActionEditGfpp = ({
  controllerBuilder,
  editorSDK,
  flowAPI,
  role,
  label,
  focusOn,
  isGalleryComponent,
}: {
  controllerBuilder: WidgetBuilder;
  editorSDK: FlowEditorSDK;
  flowAPI: FlowAPI;
  role: string;
  label: string;
  focusOn?: string;
  isGalleryComponent?: boolean;
}) => {
  controllerBuilder.configureConnectedComponents(role, (componentBuilder) => {
    componentBuilder
      .gfpp()
      .set('mainAction2', {
        label,
        onClick: getCollectionIdAndOpenDashboard({
          editorSDK,
          flowAPI,
          focusOn,
          isGalleryComponent,
        }),
      })
      .set('connect', { behavior: 'HIDE' });
    componentBuilder.gfpp('mobile').set('mainAction2', {
      label,
      onClick: getCollectionIdAndOpenDashboard({
        editorSDK,
        flowAPI,
        focusOn,
        isGalleryComponent,
      }),
    });
    if (isGalleryComponent) {
      componentBuilder.gfpp().set('settings', { behavior: 'HIDE' });
      componentBuilder.gfpp('mobile').set('settings', { behavior: 'HIDE' });
    }
  });
};

const getWidgetCollectionId = async ({
  event,
  editorSDK,
  flowAPI,
  isGalleryComponent,
}: {
  event: CustomEvent;
  editorSDK: FlowEditorSDK;
  flowAPI: FlowAPI;
  isGalleryComponent?: boolean;
}) => {
  const { httpClient } = flowAPI;
  const { componentRef } = event.detail;
  let collectionId = '';
  try {
    const ancestorsRefs = await editorSDK.components.getAncestors('', {
      componentRef,
    });
    const parentPosition = isGalleryComponent
      ? FeaturedCollectionPosition.GalleryAncestorPosition
      : FeaturedCollectionPosition.TextsAncestorPosition;
    const props = (await editorSDK.application.appStudioWidgets.props.get('', {
      widgetRef: ancestorsRefs[parentPosition],
    })) as FeaturedCollectionWidgetWidgetProps;
    if (props?.data) {
      const selectedCollections = props.data.selectedCollections || [];
      collectionId = selectedCollections[0];
    }
  } catch (e: any) {
    flowAPI.reportError(e);
    console.error('Failed to get collection id from widget', e);
  }

  if (!collectionId) {
    const pageRef = await editorSDK.pages.getCurrent('');
    const isDynamicPage = await editorSDK.pages.isDynamicPage('', { pageRef });
    if (isDynamicPage) {
      const dynamicPageProjectId = await getCollectionIdFromDynamicPage(
        editorSDK,
        httpClient,
      );
      collectionId = dynamicPageProjectId as string;
    } else {
      const firstCollection = await getFirstCollection(httpClient);
      collectionId = firstCollection.id as string;
    }
  }
  return collectionId;
};

const getCollectionIdAndOpenDashboard =
  ({
    editorSDK,
    flowAPI,
    focusOn,
    isGalleryComponent,
  }: {
    editorSDK: FlowEditorSDK;
    flowAPI: FlowAPI;
    focusOn?: string;
    isGalleryComponent?: boolean;
  }) =>
  async (event: CustomEvent) => {
    const collectionId = await getWidgetCollectionId({
      event,
      editorSDK,
      flowAPI,
      isGalleryComponent,
    });
    openDashboardPanel(
      editorSDK,
      `portfolio/collection/${collectionId}${
        focusOn ? `?pfocus=${focusOn}` : ''
      }`,
    );
  };

export const setConfigureConnectedComponents = ({
  controllerBuilder,
  editorSDK,
  flowAPI,
}: {
  controllerBuilder: WidgetBuilder;
  editorSDK: FlowEditorSDK;
  flowAPI: FlowAPI;
}) => {
  const { t } = flowAPI.translations;

  CollectionConfigureMainActionEditGfpp({
    controllerBuilder,
    editorSDK,
    flowAPI,
    role: getRole(CollectionWidgetComponentIds.Title),
    label: t('Collection_Widget_Connected_Component_Title'),
  });

  CollectionConfigureMainActionEditGfpp({
    controllerBuilder,
    editorSDK,
    flowAPI,
    role: getRole(CollectionWidgetComponentIds.Description),
    label: t('Collection_Widget_Connected_Component_Description'),
    focusOn: COLLECTION_PAGE_AUTO_FOCUS_TARGETS.DESCRIPTION,
  });

  CollectionConfigureMainActionEditGfpp({
    controllerBuilder,
    editorSDK,
    flowAPI,
    role: getRole(CollectionWidgetComponentIds.Gallery),
    label: t('Collection_Widget_Connected_Component_Collection_Gallery'),
    focusOn: COLLECTION_PAGE_AUTO_FOCUS_TARGETS.PROJECTS,
    isGalleryComponent: true,
  });
};
